import Vue from 'vue'
import Router from 'vue-router'

import HomeContainer from '@/components/HomeContainer'
import NotFoundPage from '@/components/NotFoundPage'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '@/components/login/LoginContainer'),
      beforeEnter: (to, from, next) => {
        Vue.session.validate((session, error) => {
          if (!error && session.DATA07 !== '0' && session.customerId && session.customerType) {
            next({ name: 'Menu' })
          }
          if (error?.errorCode && error?.errorCode === 1) {
            next()
          } else if (Vue.keycloak.authenticated && to.name !== 'CustomerSelection') {
            next({ name: 'CustomerSelection' })
          } else {
            next()
          }
        })
      },
      children: [
        {
          path: '',
          name: 'Login',
          component: () => import(/* webpackChunkName: "login" */ '@/components/login/AccessMethod')
        },
        {
          path: 'customer',
          name: 'CustomerSelection',
          component: () => import(/* webpackChunkName: "login" */ '@/components/login/CustomerSelection')
        }
      ]
    },
    {
      path: '/',
      component: HomeContainer,
      beforeEnter: (to, from, next) => {
        Vue.session.validate((session, error) => {
          if (!error && session.DATA07 !== '0' && session.customerId && session.customerType) {
            next()
          } else {
            next({ name: 'Login' })
          }
        })
      },
      beforeRouteUpdate: (to, from, next) => {
        next()
      },
      children: [
        {
          path: '',
          name: 'Menu',
          component: () => import(/* webpackChunkName: "Menu" */ '@/components/Menu')
        },
        {
          path: 'create',
          name: 'CreateHome',
          component: () => import(/* webpackChunkName: "CreateHome" */ '@/components/Create/CreateHome')
        },
        {
          path: 'find',
          name: 'FindHome',
          component: () => import(/* webpackChunkName: "FindHome" */ '@/components/Find/FindHome'),
          props: true
        },
        {
          path: 'detail',
          name: 'Detail',
          component: () => import(/* webpackChunkName: "Detail" */ '@/components/Find/TicketDetail')
        }
      ]
    },
    {
      path: '/:catchAll(.*)',
      component: NotFoundPage,
      name: 'NotFound',
      redirect: '/'
    }
  ]
})

export default router
