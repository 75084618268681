export default {
  btnBack: 'Volver',
  btnExport: 'Exportar',
  titlePending: 'Solicitudes Pendientes',
  titleHistory: 'Historial de Casos Finalizados',
  titleFindTicket: 'Tickets',
  error_no_request: 'No tiene historial de tickets.',
  no_tickets_found: 'No se encontraron resultados',
  invalid_application_number: 'No existe el ticket. Verifique el número de esta.',
  searchError: 'Debe ingresar los datos solicitados para realizar la busqueda.'
}
