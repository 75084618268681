import login from './login'
import menu from './menu'
import create from './create/create'
import find from './find/find'
import errors from './errors'
import calendar from './calendar'
import input from './input'
import ticket from './ticket'
import perfil from './perfil/perfil'
import navigator from './navigator'
import attachment from './attachment/attachment'
import awk from './awk'

export default {
  login: login,
  menu: menu,
  create: create,
  find: find,
  calendar: calendar,
  input: input,
  errors: errors,
  ticket: ticket,
  perfil: perfil,
  navigator: navigator,
  attachment: attachment,
  awk: awk
}
