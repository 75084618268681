import _ from 'lodash'

const directiveMethod = (el, binding, vnode) => {
  /*
  UPD 05-19-2017: My latest code. I define setAttribute() and check for vnode.componentInstance to prevent js errors when using with both html elements and Vue components.
  */
  let hasPermission = false
  if (binding.arg) {
    hasPermission = vnode.context.$session.hasPermission(binding.arg)
  } else if (binding.value) {
    const args = binding.value.split(' ')
    let flagOr = false
    let flagAnd = false
    _.each(args, elm => {
      switch (elm) {
        case 'or':
          flagOr = true
          flagAnd = false
          break

        case 'and':
          flagAnd = true
          flagOr = false
          break

        default:
          if (flagOr) {
            hasPermission = hasPermission || vnode.context.$session.hasPermission(elm)
          } else if (flagAnd) {
            hasPermission = hasPermission && vnode.context.$session.hasPermission(elm)
          } else {
            hasPermission = vnode.context.$session.hasPermission(elm)
          }
      }
    })
  }
  if (!hasPermission) {
    // replace HTMLElement with comment node
    const comment = document.createComment(' ')
    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined
    })
    vnode.elm = comment
    vnode.text = ' '
    vnode.isComment = true
    vnode.context = undefined
    vnode.tag = undefined
    vnode.data.directives = undefined

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el)
    }
  }
}

export default {
  install (Vue) {
    Vue.directive('permission', directiveMethod)
  }
}
