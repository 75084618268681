export default {
  title: 'Ticket Nro. ',
  ticket: 'Ticket',
  lbl_created: 'Creado el',
  creationDate: 'Fecha Creación',
  status: 'Estado: : ',
  lbl_requirement_classification: 'Clasificación del requerimiento',
  petition: 'Petición',
  description: 'Descripción',
  reference: 'Referencia',
  resolution: 'Resolución',
  item: {
    ticket: 'El ticket',
    created: 'fue creado el',
    finished: 'con término el',
    open: 'Abierto',
    close: 'Cerrado',
    more: 'Ver más',
    less: 'Ver menos'
  },
  lbl_customer_data: 'Datos Personales',
  lbl_contact_data: 'Datos del Contacto',
  lbl_attachment: 'Archivos adjuntos',
  lbl_back: 'Volver'
}
