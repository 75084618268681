<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import 'noty/src/themes/bootstrap-v4.scss'
import '@/sass/app.scss'

export default {
  name: 'App',
  components: {
  }
}
</script>
