export default {
  title: 'Mi Perfil',
  lbl_email: 'Email',
  lbl_document_id: 'Rut',
  lbl_name: 'Nombre',
  lbl_phone: 'Teléfono de Contacto',
  lbl_address: 'Dirección Personal',
  lbl_birthdate: 'Fecha de Nacimiento',
  lbl_gender: 'Sexo',
  btn_save: 'Guardar',
  btn_change_password: 'Cambiar Contraseña',
  title_change_email: 'Cambio Correo Electrónico',
  subtitle_change_email: 'Para cambiar su correo Electrónico, ingrese su correo nuevo y su contraseña actual dos veces.',
  title_change_password: 'Cambio Contraseña Usuario',
  subtitle_change_password: 'Para cambiar su contraseña, ingrese la actualy luego escriba su contraseña nueva dos veces.',
  lbl_new_email: 'Correo Electrónico Nuevo',
  lbl_current_password: 'Contraseña Actual',
  lbl_confirm_password: 'Confirmación Contraseña',
  lbl_new_password: 'Nueva Contraseña',
  btn_cancel: 'Cancelar',
  btn_change: 'Cambiar',
  btnBack: 'Volver'
}
