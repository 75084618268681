import prod from './pro'
// import dev from './dev'

// let currentConfig = dev

// if (process.env.NODE_ENV === 'production') {
//   currentConfig = prod
// }

// export default currentConfig
export default prod
