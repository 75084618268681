import Vue from 'vue'

const tabsBus = new Vue()

const tabsPlugin = {
  open (eventData) {
    tabsBus.$emit('openTab', eventData)
  },
  onOpen (callback) {
    tabsBus.$on('openTab', callback)
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$tabs = tabsPlugin
    Vue.tabs = tabsPlugin
  }
}
