export default {
  COMM: 'Error al contactar al servidor',
  PROCESS: 'Ha ocurrido un error en la acción realizada. Favor intentar nuevamente o contactar a mesa de ayuda si el problema persiste.',
  AUTH: 'Error de autenticación',
  CAPTURE_MATCH: 'Error huellas no coinciden',
  QUESTION_MATCH: 'Respuesta no coincide',
  CAPTURE_GENERAL: 'Error al capturar huellas',
  REQUIRED_FIELD: 'Por Favor complete los campos en rojo',
  CLOSED: 'Cerrado por el usuario',
  WS_CONNECTION: 'No es posible contactar componente local',
  REQUIRED: 'Campo requerido',
  INVALID_FORMAT: 'Formato inválido'
}
