export default {
  title_filters: 'Historia',
  subtitle_filters: 'Utilice los filtros para acotar su búsqueda',

  lbl_ticket: 'Ticket Nro.',
  lbl_deadline: 'Plazo máximo de término',

  view: 'Visualizar',
  group: 'Grupo',
  group_served: 'Grupo atendido',
  ticket: 'Ticket',
  date: 'Fecha historia',
  plan: 'Plan',
  campaign: 'Campaña',
  motive1: 'Motivo 1',
  motive2: 'Motivo 2',
  motive3: 'Motivo 3',
  term1: 'Término 1',
  term2: 'Término 2',
  term3: 'Término 3',
  cod_customer_type: 'Tipo de cliente',
  cod_customer_id: 'Tipo de documento',
  customer_id: 'Identificador',
  customer_name: 'Nombre o descriptor',
  cod_contact_type: 'Tipo de contacto',
  cod_contact_id: 'Tipo de documento',
  contact_id: 'Identificador',
  contact_name: 'Nombre',
  contact_lastname: 'Apellido paterno',
  state: 'Estado',
  state_options: {
    ABIER: 'Abiertos',
    CERRA: 'Cerrados'
  },
  search_in: 'Buscar en',
  search_options: {
    A: 'En línea',
    H: 'Historia'
  },
  records_per_page: 'Visualización por página',
  records: 'registros',
  records_options: {
    5: 5,
    10: 10,
    20: 20
  },

  all: 'Todos',
  none: 'Ninguna',
  my_tickets: 'Mis gestiones',
  my_tickets_served: 'Gestiones atendidas',
  all_company: 'Toda la empresa',
  opt_select: 'Seleccione',

  search: 'Buscar',

  title_search: 'Búsqueda',
  subtitle_search: 'resultados encontrados para los filtros ingresados',

  new_ticket_action: 'Crear gestión',
  new_ticket_msg: 'O presione aquí para crear una nueva gestión',

  no_results_found: 'No se encontraron registros con los filtros seleccionados',

  item: {
    ticket: 'La gestión',
    created: 'fue creado el',
    finished: 'con término el',
    open: 'Abierto',
    close: 'Cerrado',
    more: 'Ver más',
    less: 'Ver menos'
  }
}
