export default {
  message: 'Hola, ¿en qué te podemos ayudar? Por favor selecciona la categoría que más se acerque a tu necesidad.',
  message2_1: 'Entiendo. Deseas realizar un requerimiento de',
  message2_2: 'para el departamento de',
  message2_3: 'sobre',
  message2_4: 'Por favor completa la información solicitada.',
  optionSelect: 'Seleccione',
  petition: 'Petición',
  upload: 'Subir Archivo',
  noFile: 'No hay archivo',
  btnCancel: 'Cancelar',
  btnSend: 'Enviar',
  thTicket: 'Ticket',
  thId: 'Rut',
  thName: 'Nombre',
  thCreate: 'Creación',
  thresolution: 'Resolución',
  thReason1: 'Motivo 1',
  thReason2: 'Motivo 2',
  thReason3: 'Motivo 3',
  title_nothing: 'Ninguno',
  title_description1: 'Motivo 1',
  title_description2: 'Motivo 2',
  title_description3: 'Motivo 3',
  characters_remaining: ' caracteres restantes',
  reference: 'Referencia',
  description: 'Descripción',

  next_step: 'Siguiente',
  cancel: 'Cancelar',

  variable_title: 'Datos'
}
