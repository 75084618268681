export default {
  fillInformation: 'Ingrese a continuación los datos del cliente',
  thirdPartyInformation: 'La incidencia requiere datos de terceros?',
  message: 'Ingresa a continuación la información solicitada.',
  perfect: 'Perfecto!',
  completeInformation: 'Completa los siguientes datos',
  ownName: '¿Radica a nombre propio?',
  optionSelect: 'Seleccione',
  optionYes: 'Sí',
  optionNo: 'No',
  documentType: 'Tipo de documento *',
  documentNumber: 'Número de documento *',
  fullName: 'Nombre completo*',
  petitionerDocumentType: 'Tipo de documento',
  petitionerDocumentNumber: 'Numero de documento',
  petitionerFullName: 'Nombre',
  phone: 'Teléfono *',
  email: 'Correo Electrónico *',
  requiredfields: 'Campos obligatorios (*)',
  validInputMessage: 'Debe llenar los campos obligatorios',
  invalid_email: 'Verifique el email ingresado es valido',
  next_step: 'Siguiente',
  cancel: 'Cancelar'
}
