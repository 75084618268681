export default {
  new_ticket: 'Nueva gestión',
  about_ticket: 'Acerca de la Gestión',
  about_customer: 'Acerca del Cliente',
  about_contact: 'Acerca del Contacto',
  customer_title: 'Datos del Cliente',
  document_type: 'Documento',
  customer_id: 'ID Cliente',
  customer_name: 'Nombre Cliente',

  contact_title: 'Datos del Contacto',
  contact_name: 'Nombre Contacto',
  last_name1: 'Apellido Paterno',
  last_name2: 'Apellido Materno',

  motives_title: 'Clasificación',
  motive1: 'Motivo 1',
  motive2: 'Motivo 2',
  motive3: 'Motivo 3',

  description_title: 'Descripción Solicitud',
  reference: 'Referencia Clasificación',
  description: 'Descripción Clasificación',
  characters_remaining: ' caracteres restantes',
  max_description_alert: 'Descripción clasificación supera el largo máximo permitido',

  resolution_title: 'Descripción Resolución',
  reference_resolution: 'Referencia Resolución',
  description_resolution: 'Descripción Resolución',
  max_description_resolution_alert: 'Descripción resolución supera el largo máximo permitido',

  terms_title: 'Resolución',
  term1: 'Término 1',
  term2: 'Término 2',
  term3: 'Término 3',

  title_nothing: 'Ninguno',

  state_ticket: 'Estado <%= toDo.statusDescription %>',
  date_ticket: 'Creado el <%= currentDate %> <% if (endDate !== "") { %> hasta <%= endDate %> <% } %>',

  task: 'Tarea',
  start_task: 'Inicio tarea',
  term_task: 'Plazo tarea',

  originator_user: 'Originador',
  start_ticket: 'Inicio gestión',
  term_ticket: 'Plazo gestión',

  responsible: 'Responsable',
  role: 'Rol',
  reviewed: 'Revisado hasta',

  state: 'Estado',

  save: 'Guardar Gestión',
  dispatch_title: 'Despachar',

  dispatch: {
    decision: 'Decisión',
    responsable: 'Responsable',
    compromiseDate: 'Compromiso'
  },

  variable_title: 'Datos',

  alert_cod_customer_type: 'Debe seleccionar tipo cliente',
  alert_cod_customer_id: 'Debe seleccionar identificador cliente',
  alert_customer_id: 'Debe ingresar RUT del cliente',
  not_found_customer: 'Cliente no encontrado',

  alert_ticket_process: 'Gestión en proceso de actualización. Reintente en unos minutos',
  alert_ticket_dispatch: 'La gestión ha sido despachada por otro usuario. Ábrala nuevamente',

  alert_validate_dispatch: 'Debe completar los campos obligatorios para continuar',
  alert_validate_attachment: 'Debe adjuntar documentos obligatorios, favor verificar en sección Atajos',
  alert_validate_checklist: 'Debe chequear actividades obligatorias, favor verificar en sección Atajos',
  alert_validate_terms: 'Debe seleccionar término',
  alert_validate_resolution: 'Debe ingresar glosa de resolución',
  confirm_rule: '¿Desea continuar?',

  close_group: 'Cerrar <%= item.dataName %>',

  shortcut: {
    shortcuts: 'Atajos',
    action: 'Acción',
    attachment: 'Adjunto',
    note: 'Glosa',
    insistence: 'Insistencias'
  },
  campaign_placeholder: 'Imagen campaña',

  insistence: {
    title: 'Seleccione ticket para agregar insistencia',
    create_ticket: 'Crear ticket',
    add_insistence: 'Agregar insistencia',
    cancel: 'Cancelar',
    insistence_on_time: 'Insistencia para ticket dentro de plazo de gestión',
    insistence_of_time: 'Insistencia para ticket fuera de plazo de gestión',
    create_insistence_success: 'La insistencia ha sido creada satisfactoriamente',

    description: 'Insistencia para ticket <span class="has-text-weight-bold"><%= ticket.contactId %>-<%= ticket.serialId %></span>, cliente <span class="has-text-weight-bold"><%= ticket.customerId %></span> <%= ticket.customerName %> <% if (ticket.motiveDescription1 !== "") { %>, con la tipificación <%= ticket.motiveDescription1 %> <% } %> <% if (ticket.motiveDescription2 !== "") { %> / <%= ticket.motiveDescription2 %> <% } %> <% if (ticket.motiveDescription3 !== "") { %> / <%= ticket.motiveDescription3 %> <% } %> con fecha de creación <span class="has-text-weight-bold"><%= creationDate %></span> y fecha de plazo <span class="has-text-weight-bold"><%= deadlineDate %></span>'
  }
}
