import _ from 'lodash'
import axios from 'axios'
import config from '@/config'
import router from '@/router'

const prefix = 'sds-'

const Session = {
  currentSession: null,
  currentSessionId: null,
  timer: null,
  keycloak: null,

  save (session) {
    if (sessionStorage) {
      sessionStorage.setItem(`${prefix}sesion`, JSON.stringify(session))
    }
    this.currentSession = session
  },

  get () {
    let session = null
    if (!sessionStorage) {
      session = this.currentSession
    } else {
      const jsonSession = sessionStorage.getItem(`${prefix}sesion`)
      if (jsonSession) {
        session = JSON.parse(jsonSession)
      }
    }
    return session
  },

  id (sessionId) {
    if (sessionId) {
      if (!localStorage) {
        this.currentSessionId = sessionId
      } else {
        localStorage.setItem(`${prefix}sid`, sessionId)
      }
    } else {
      if (!localStorage) {
        sessionId = this.currentSessionId
      } else {
        sessionId = localStorage.getItem(`${prefix}sid`)
      }
    }

    return sessionId
  },

  apiKey (apiKey) {
    if (apiKey) {
      if (!localStorage) {
        this.currentapiKey = apiKey
      } else {
        localStorage.setItem(`${prefix}rsid`, apiKey)
      }
    } else {
      if (!localStorage) {
        apiKey = this.currentapiKey
      } else {
        apiKey = localStorage.getItem(`${prefix}rsid`)
      }
    }

    return apiKey
  },

  hasPermission (permission) {
    const permissions = this.get().permissions

    return permissions[permission]
  },

  clear () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }

    const apiKey = this.apiKey()

    if (apiKey) {
      this.http()
        .then(http =>
          http({
            method: 'delete',
            url: `${config.session.endpoint}/session`
          })
        )
        .catch(() => {
          // ignore
        })
        .finally(() => {
          if (localStorage) {
            sessionStorage.removeItem(`${prefix}sesion`)
          }
          if (sessionStorage) {
            localStorage.removeItem(`${prefix}sid`)
            localStorage.removeItem(`${prefix}rsid`)
            localStorage.removeItem(`${prefix}rfid`)
            localStorage.removeItem(`${prefix}seid`)
          }
        })
    } else {
      if (localStorage) {
        sessionStorage.removeItem(`${prefix}sesion`)
      }
      if (sessionStorage) {
        localStorage.removeItem(`${prefix}sid`)
        localStorage.removeItem(`${prefix}rsid`)
        localStorage.removeItem(`${prefix}rfid`)
        localStorage.removeItem(`${prefix}seid`)
      }
    }
  },

  validate (callback) {
    const apiKey = this.apiKey()

    if (!apiKey) {
      return callback(null, { errorCode: 1 })
    }

    this.http()
      .then(http =>
        http({
          method: 'get',
          url: `${config.session.endpoint}/session`
        })
      )
      .then(response => {
        const data = response.data
        if (data.errorNum < 0) {
          // 'Su sesión no existe o ha sido reemplazado por otro usuario'
          return callback(null, { errorCode: 2 })
        } else if (data.errorNum !== 0) {
          return callback(null, { errorCode: 3 })
        } else {
          const session = data.data.session

          session.permissions = data.data.permissions

          this.save(session)

          return callback(session)
        }
      })
      .catch((e) => callback(null, { errorCode: 4, source: e }))
  },

  getSession () {
    Session.validate((session, error) => {
      if (!session) {
        Session.clear()
        router.push({ name: 'Login' })
      }
    })
  },

  permission (expression, hasCondition) {
    let hasPermission = false
    if (!hasCondition) {
      hasPermission = Session.hasPermission(expression)
    } else {
      const args = expression.split(' ')
      let flagOr = false
      let flagAnd = false
      _.each(args, elm => {
        switch (elm) {
          case 'or':
            flagOr = true
            flagAnd = false
            break

          case 'and':
            flagAnd = true
            flagOr = false
            break

          default:
            if (flagOr) {
              hasPermission = hasPermission || Session.hasPermission(elm)
            } else if (flagAnd) {
              hasPermission = hasPermission && Session.hasPermission(elm)
            } else {
              hasPermission = Session.hasPermission(elm)
            }
        }
      })
    }
    return hasPermission
  },

  async http () {
    await Session.refreshToken()
    const headers = {
      Authorization: `Bearer ${this.apiKey()}`
    }
    return axios.create({ headers })
  },

  setTokens (response) {
    this.apiKey(response.access_token)
    localStorage.setItem(`${prefix}rfid`, response.refresh_token)
    if (response.session_state) {
      localStorage.setItem(`${prefix}seid`, response.session_state)
    }

    const nextRefresh = (response.expires_in - 20) * 100

    this.timer = setTimeout(() => {
      const refreshToken = localStorage.getItem(`${prefix}rfid`)

      if (!refreshToken) {
        return
      }

      const params = new URLSearchParams()
      params.append('grant_type', 'refresh_token')
      params.append('client_id', config.auth.clientId)
      params.append('client_secret', config.auth.secret)
      params.append('refresh_token', refreshToken)

      axios
        .post(`${config.auth.url}/realms/${config.auth.realm}/protocol/openid-connect/token`, params)
        .then(response => {
          this.setTokens(response.data)
        })
        .catch(e => {
          console.error(e)
        })
    }, nextRefresh)
  },

  async refreshToken () {
    if (this.keycloak?.authenticated) {
      if (this.keycloak.isTokenExpired(30)) {
        const success = await this.keycloak.updateToken(30)
        if (success) {
          Session.apiKey(this.keycloak.token)
        }
      }
    }
  }
}

export default {
  install: function (Vue) {
    Vue.prototype.$session = Vue.observable(Session)
    Vue.session = Session
    Vue.prototype.$permission = Session.permission
    Vue.permission = Session.permission
  }
}
