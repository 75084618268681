import Vue from 'vue'

import alert from './alert'
import handle from './handle'
import loading from './loading'
import tabs from './tabs'
import session from './session'
import counter from './counter'

Vue.use(alert)
Vue.use(handle)
Vue.use(loading)
Vue.use(tabs)
Vue.use(session)
Vue.use(counter)
