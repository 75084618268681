<template>
  <div class="hero is-fullwidth has-background">
    <div class="hero-body">
      <div class="container has-text-centered">
        <figure class="image is-inline-block">
          <img
            src="@/images/solem-2018-w.png"
            alt="SOLEM"
          >
        </figure>
        <div>
          <span class="has-text-grey-lighter is-size-4 is-size-6-mobile is-uppercase has-text-weight-semibold">{{ text.title_app }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  data () {
    return {
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].login
    }
  },
  methods: {
  }
}
</script>

<style type="text/css">
  .main-header .hero-body .image {
    width: 16em;
  }
  .main-header .has-background {
    background-image: url(@/images/background_black.jpg) !important;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>
