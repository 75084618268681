<template>
  <div class="block">
    <nav
      class="navbar is-light"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarmenu"
          :class="{ 'is-active': showNav }"
          @click="showNav = !showNav"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div
        id="navbarmenu"
        class="navbar-menu"
        :class="{ 'is-active': showNav }"
      >
        <div class="navbar-start">
          <a
            class="navbar-item"
            :class="{ 'navbar-item-selected': currentNav === 'Menu' }"
            @click="onSelectAction('Menu')"
          >{{ text.home }}</a>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">{{ text.services }}</a>
            <div class="navbar-dropdown">
              <a
                v-permission:ADTKT
                class="navbar-item"
                :class="{ 'navbar-item-selected': currentNav === 'CreateHome' }"
                @click="onSelectAction('CreateHome')"
              >{{ text.create }}</a>
              <a
                v-permission:VITKT
                class="navbar-item"
                :class="{ 'navbar-item-selected': currentNav === 'FindHome' }"
                @click="onSelectFindAction('FindHome', '', true)"
              >{{ text.find }}</a>
            </div>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item has-dropdown is-hoverable">
            <div class="navbar-link">
              <span class="icon is-medium"><i class="fas fa-user" /></span>
              <span class="has-text-weight-medium">{{ originName }}</span>
            </div>
            <div class="navbar-dropdown is-right has-text-right">
              <a
                class="navbar-item"
                @click="onCloseSesion()"
              >
                <span class="icon">
                  <i class="fas fa-sign-out-alt" />
                </span>
                <span>{{ text.exit }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Vue from 'vue'
import router from '@/router'

export default {
  name: 'Navigator',
  data () {
    return {
      showNav: false
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].navigator
    },
    version () {
      return `Auris v${this.$root.version}`
    },
    session () {
      return this.$session.get()
    },
    originName () {
      return `${this.$session.get().DATA04} ${this.$session.get().DATA05}`
    },
    currentNav () {
      return this.$route.name
    }
  },
  methods: {
    onSelectAction (action) {
      if (this.$route.name !== action) {
        this.$router.push({ name: action })
        this.showNav = false
      }
    },
    onSelectFindAction (name, statusCode, viewFinder) {
      if (this.$route.name !== name) {
        this.$router.push({ name: name, params: { statusCode: statusCode, viewFinder: viewFinder } })
        this.showNav = false
      }
    },
    onCloseSesion () {
      this.$session.clear()
      Vue.keycloak.logout()
      router.push('/login')
    }
  }
}
</script>
