import createHome from './createHome'
import address from './address'
import step1 from './step1'
import step2 from './step2'
import customerInformation from './customerInformation'
import createSuccess from './createSuccess'
import createInsistence from './createInsistence'
import stepLine from './stepLine'
import categorization from './categorization'
import record from './record'

export default {
  createHome: createHome,
  address: address,
  step1: step1,
  step2: step2,
  customerInformation: customerInformation,
  createSuccess: createSuccess,
  createInsistence: createInsistence,
  stepLine: stepLine,
  categorization: categorization,
  record: record
}
