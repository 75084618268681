export default {
  title: 'Ingreso al sistema de registros de incidentes',
  title_app: 'Registros de incidentes',
  subtitle: 'Bienvenido, Por favor, seleccione cliente para acceder',
  customerType: 'Tipo de cliente',
  customerIdType: 'Tipo de identificador',
  customerId: 'Identificador',
  customer: 'Cliente',
  user: 'Usuario',
  password: 'Contraseña',
  access: 'Ingresar',
  passwordRecovery: 'Recuperar contraseña',
  customer_no_found: 'No se encontraron clientes asignados',
  customer_find_error: 'Hubo un error al buscar clientes asignados',
  access_method_subtitle: 'Bienvenido, Por favor, seleccione un medio de acceso.',
  btn_access_annonymous: 'Acceso anonimo',
  btn_access_login: 'Acceso con usuario y contraseña',
  btn_back: 'Volver'
}
