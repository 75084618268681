import Vue from 'vue'
import App from './App.vue'
import Keycloak from 'keycloak-js'

import config from '@/config'
import router from './router'
import text from './text'

import './plugins'
import './directives'

import moment from 'moment'
import numeral from 'numeral'

import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

import 'numeral/locales/es'
import axios from 'axios'

const defaultLanguage = 'es'

moment.locale(defaultLanguage)
numeral.locale(defaultLanguage)

Vue.config.productionTip = false
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.component('Message', () => import('./components/awk/Message'))
Vue.use(VueReCaptcha, { siteKey: config.recaptcha.sitekey })

// const initOptions = {
//   url: 'https://auth.auris.cloud/',
//   realm: 'auris-dev',
//   clientId: 'self-support-web-dev',
//   onLoad: 'login-required'
// }

let keycloak = null

axios
  .get(`${config.instance.endpoint}/instance`)
  .then(response => {
    const config = response.data.data

    if (!config?.domain?.auth?.sdsselfservice) {
      // errorType = 'instance'
      // requiredReload = true
      return Promise.reject(new Error('Invalid instance'))
    }

    return Promise.resolve(config.domain.auth.sdsselfservice)
  })
  .then(initOptions => {
    keycloak = new Keycloak(initOptions)

    Vue.prototype.$keycloak = keycloak
    Vue.keycloak = keycloak

    return keycloak.init({ })
  })
  .then(auth => {
    Vue.session.keycloak = keycloak
    if (auth) {
      Vue.session.apiKey(keycloak.token)
    }
    new Vue({
      data: {
        language: defaultLanguage,
        globalText: text,
        version: process.env.PACKAGE_VERSION
      },
      watch: {
        language (value) {
          moment.locale(value)
          numeral.locale(value)
        }
      },
      methods: {
        async recaptcha () {
          await this.$recaptchaLoaded()
          // const token = await this.$executeRecaptcha('login')
        }
      },
      render: h => h(App),
      router,
      template: '<button @click="recaptcha">Execute recaptcha</button>'
    }).$mount('#app')
  })
  .catch(e => {
    console.error(e)
  })
