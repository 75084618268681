export default {
  lbl_add: 'Adjunta',
  lbl_documents: 'los documentos que sean necesarios.',
  error_no_file: 'No ha adjuntado archivo.',
  error_invalid_attachment_extension: 'Extensión de archivo inválida.',
  create_attachment_file_number_alert: 'Sólo puedes agregar 3 archivos. Si desea continuar debe descartar un archivo',
  delete_attachment_success: 'Adjunto eliminado con éxito.',
  created_attachment_success: 'Adjunto creado con éxito.',
  find_file: 'Examinar',
  finalize: 'Finalizar'
}
