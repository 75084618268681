import findHome from './findHome'
import finder from './finder'
import ticketDetail from './ticketDetail'
import ticketItem from './ticketItem'
import history from './history'

export default {
  findHome: findHome,
  finder: finder,
  ticketDetail: ticketDetail,
  ticketItem: ticketItem,
  history: history
}
