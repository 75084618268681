import router from '../router'

export default {
  install: function (Vue) {
    Vue.prototype.$handle = function () {
      const vueCtx = this

      return {
        handleErrorWith (messages) {
          return error => {
            const errors = vueCtx.$root.globalText[vueCtx.$root.language].errors
            let message = errors.PROCESS
            let type = 'error'
            let ignore = false
            let logout = false
            let callback = null

            if (error.response) {
              if (error.response.data) {
                if (messages && messages[error.response.status]) {
                  if (messages[error.response.status][error.response.data.errorNum]) {
                    if (messages[error.response.status][error.response.data.errorNum].message) {
                      message = messages[error.response.status][error.response.data.errorNum].message
                    }
                    if (messages[error.response.status][error.response.data.errorNum].type) {
                      type = messages[error.response.status][error.response.data.errorNum].type
                    }
                    if (messages[error.response.status][error.response.data.errorNum].ignore) {
                      ignore = true
                    }
                    if (messages[error.response.status][error.response.data.errorNum].callback) {
                      callback = messages[error.response.status][error.response.data.errorNum].callback
                    }
                  } else {
                    if (messages[error.response.status].message) {
                      message = messages[error.response.status].message
                    }
                    if (messages[error.response.status].type) {
                      type = messages[error.response.status].type
                    }
                    if (messages[error.response.status].ignore) {
                      ignore = true
                    }
                    if (messages[error.response.status].callback) {
                      callback = messages[error.response.status].callback
                    }
                  }
                } else {
                  if (error.response.status === 401 || error.response.status === 403) {
                    message = errors.AUTH
                  }
                }
              } else {
                message = errors.COMM
              }

              logout = error.response.status === 401 || error.response.status === 403
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              // console.log(error.request)
              message = errors.COMM
            } else {
              // Something happened in setting up the request that triggered an Error
              // console.log('Error', error.message)
              message = errors.COMM
            }

            if (!ignore) {
              vueCtx.$alert.message(message, type)
            }

            if (logout) {
              router.push({ name: 'Login' })
              return
            }

            if (callback) {
              callback(error)
            }
          }
        },
        error (error) {
          console.error('error', error)
          const errors = vueCtx.$root.globalText[vueCtx.$root.language].errors
          let message = ''

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data)
            // console.log(error.response.status)
            // console.log(error.response.headers)

            if (error.response.status === 401 || error.response.status === 403) {
              message = errors.AUTH
              router.push({ name: 'Login' })
            } else {
              message = errors.PROCESS
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            // console.log(error.request)
            message = errors.COMM
          } else {
            // Something happened in setting up the request that triggered an Error
            // console.log('Error', error.message)
            message = errors.COMM
          }
          // console.log(error.config)

          vueCtx.$alert.error(message)
        }
      }
    }
  }
}
