export default {
  title_personal_data: 'Datos del responsable',
  title_company: 'Empresa',
  subtitle_id: 'Id Responsable',
  subtitle_name: 'Nombre Completo',
  subtitle_phone: 'Teléfono',
  subtitle_email: 'Correo electrónico',
  subtitle_company: 'Este ticket se esta registrando en',
  lbl_services: 'Servicio',
  error_contract_selected: 'Debe seleccionar un servicio.',
  error_reference_empty: 'La referencia del ticket es un dato obligatorio.',
  error_description_empty: 'La descripción del ticket es un dato obligatorio.',
  alert_motive1: 'Debe seleccionar producto en categoría.',
  select: 'Seleccione',
  na: 'Sin información',
  next_step: 'Siguiente',
  cancel: 'Cancelar'
}
