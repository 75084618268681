export default {
  home: 'Inicio',
  profile: 'Mi Perfil',
  services: 'Servicios',
  create: 'Nuevo ticket',
  pending: 'Solicitudes Pendientes',
  history: 'Historial de casos finalizados',
  find: 'Busca tickets',
  exit: 'Salir'
}
