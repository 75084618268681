export default {
  one: '1',
  two: '2',
  three: '3',
  four: '4',
  five: '5',
  title_categorization: 'Categorización',
  title_customer_data: 'Datos Personales',
  title_ticket_data: 'Antecedentes',
  title_attachments: 'Adjuntos',
  title_final: 'Finalización',
  title_campaign: 'Proceso'
}
