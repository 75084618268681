export default {
  titleCreate: 'Nuevo Ticket',
  subtitleCreate: 'Reporta incidencia',
  titlePending: 'Solicitudes Pendientes',
  subtitlePending: 'Revisa el Estado de tus Solicitudes Pendientes',
  titleHistory: 'Historial de Casos Finalizados',
  subtitleHistory: 'Revisa la información de Solicitudes ya Cerradas',
  titleFindTicket: 'Busca Tickets',
  subtitleFindTicket: 'Revisa los tickets creados'
}
