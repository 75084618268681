export default {
  title_timeline: 'Timeline',
  view: 'Visualizar',

  filter_by: 'Filtrar por tipo...',
  no_customer: 'Sin cliente asociado para realizar la búsqueda',

  filters: {
    action: {
      color: 'is-link',
      icon: 'fas fa-tasks',
      isActive: false,
      name: 'Acciones',
      permission: 'VIACT'
    },
    attachment: {
      color: 'is-warning',
      icon: 'fas fa-paperclip',
      isActive: false,
      name: 'Adjuntos'
    },
    comment: {
      color: 'is-info',
      icon: 'fas fa-comments',
      isActive: false,
      name: 'Glosas'
    },
    history: {
      color: 'is-success',
      icon: 'fas fa-history',
      isActive: false,
      name: 'Historia'
    },
    insistence: {
      color: 'is-danger',
      icon: 'fas fa-exclamation',
      isActive: false,
      name: 'Insistencias'
    },
    todo: {
      color: 'is-primary',
      icon: 'fas fa-inbox',
      isActive: false,
      name: 'Pendientes'
    }
  },

  responsible_not_asigned: 'Sin responsable',
  attachment: {
    active: 'Vigente',
    inactive: 'No vigente',
    delete: 'Eliminar',
    delete_attachment_confirm_alert: '¿Está seguro que desea eliminar el documento adjunto del cliente?',
    delete_attachment_success: 'El documento adjunto ha sido eliminado satisfactoriamente'
  },
  history: {
    campaign: 'Campaña',
    task: 'Tarea',
    motive: 'Motivo',
    term: 'Término',
    no_childs: 'No tiene hijas'
  },
  todo: {
    ticket: 'Ticket',
    campaign: 'Campaña',
    motive: 'Motivo',
    task: 'Tarea/Clasifica término',
    from: ' desde ',
    until: ' con plazo '
  },
  btn_confirm_label: 'Si',
  btn_cancel_label: 'No',

  tabs: {
    action: {
      title: 'Título de la acción',
      placeholder: 'Ingrese título de acción aquí',
      create: 'Crear acción',
      new_action: 'Nueva acción'
    },
    attachment: {
      name: 'Descripción del adjunto',
      placeholder: 'Ingrese descripción del adjunto aquí',
      type: 'Tipo del adjunto',
      select: 'Seleccione',
      file: 'Examinar',
      save: 'Guardar adjunto',
      need_required_files: 'Faltan archivos obligatorios\n',
      need_optional_files: 'Faltan archivos opcionales\n',
      files_ok: 'No faltan archivos',
      create_attachment_reference_alert: 'Debe ingresar la referencia del documento adjunto para poder continuar',
      create_attachment_type_alert: 'Debe seleccionar el tipo del documento adjunto para poder continuar',
      create_attachment_file_alert: 'Debe buscar el archivo para poder continuar',
      create_attachment_file_name_alert: 'El nombre de archivo supera el tamaño máximo',
      create_attachment_success: 'El documento adjunto ha sido creado satisfactoriamente',
      no_attachment: 'El documento adjunto no se puede recuperar',
      invalid_attachment_extension: 'Extensión de archivo inválida.<br> Se admiten las siguientes extensiones: jpg, jpeg, png, gif, docx, doc, xlsx, xls, pptx, ppt, msg, pdf.'
    },
    comment: {
      title: 'Detalle de la glosa',
      characters_remaining: ' caracteres restantes',
      placeholder: 'Haz click aquí para escribir un comentario...',
      save: 'Guardar glosa',
      create_comment_alert: 'Debe ingresar el contenido de la glosa para continuar',
      max_comment_alert: 'El contenido supera el largo máximo permitido',
      create_comment_success: 'La glosa ha sido creada satisfactoriamente'
    },
    insistence: {
      contact: 'Contacto',
      comment: 'Glosa',
      characters_remaining: ' caracteres restantes',
      placeholder: 'Haz click aquí para escribir un comentario...',
      save: 'Guardar insistencia',
      max_comment_alert: 'El contenido supera el largo máximo permitido',
      create_insistence_alert: 'Debe ingresar el contenido de la insistencia para continuar',
      create_insistence_success: 'La insistencia ha sido creada satisfactoriamente'
    }
  },

  alert_action_not_found: 'No se encontraron acciones',
  alert_attachment_not_found: 'No se encontraron adjuntos',
  alert_comment_not_found: 'No se encontraron glosas',
  alert_insistence_not_found: 'No se encontraron insistencias',

  process: 'Proceso',
  event: 'Evento',
  motive: 'Clasificación',
  term: 'Término',
  deadline_date: 'Fecha de término',
  task: 'Tarea',
  name_dispatch: 'Despachado por',
  childs_tasks: 'Ver tareas hijas',
  subflow: 'Subflujo',
  creation_date: 'Fecha de creación pendiente',
  term_date: 'Fecha plazo pendiente',
  no_motives: 'Sin clasificación'
}
