export default {
  lbl_title_finder: 'Búsqueda ticket',
  lbl_subtitle_finder: 'Utilice los filtros para acotar su búsqueda',
  lbl_ticket: 'Número de ticket',
  lbl_document: 'RUT',
  lbl_process: 'Proceso',
  lbl_date: 'Fecha',
  lbl_plan: 'Plan',
  lbl_status: 'Estado',
  ph_finder_ticket: 'Introduce número de Ticket. Ej: 1234567-1',
  ph_finder_document: 'Introduce RUT',
  title_nothing: 'Ninguno',
  none: 'Ninguno',
  view: 'Visualizar',
  my_tickets: 'Mis tickets creados',
  my_tickets_served: 'Mis tickets atendidos',
  group: 'Grupo',
  group_served: 'Grupo atendidos',
  all_company: 'Toda la empresa',
  open_tickets: 'Abiertos',
  closed_tickets: 'Cerrados',
  all_tickets: 'Todos',
  search: 'Buscar',
  searchError: 'Debe ingresar todos los datos solicitados.'
}
