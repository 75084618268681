<template>
  <div>
    <Header />
    <div class="columns is-centered">
      <div class="column is-12-tablet is-four-fifths-desktop is-two-thirds-fullhd">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Layout/Header'

export default {
  name: 'HomeContainer',
  components: {
    Header
  },
  data () {
    return {
      currentComponent: ''
    }
  },
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].menu
    },
    version () {
      return `Auris v${this.$root.version}`
    },
    session () {
      return this.$session.get()
    }
  },
  created () {
    this.currentComponent = 'Home'
  },
  methods: {
  }
}
</script>
