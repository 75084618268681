<template>
  <div>
    <div class="hero">
      <h1>Not found</h1>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFoundPage',
  computed: {
    text () {
      return this.$root.globalText[this.$root.language].menu
    }
  }
}
</script>
