export default {
  instance: {
    endpoint: 'https://solem.auris.cloud/rs/v2/instance'
  },
  selfSupport: {
    endpoint: 'https://solem.auris.cloud/api/rs/v3/self-support'
  },
  servicedesksupport: {
    endpoint: 'https://solem.auris.cloud/api/rs/v3/service-desk-support'
  },
  session: {
    endpoint: 'https://solem.auris.cloud/api/rs/v3/session'
  },
  recaptcha: {
    sitekey: '6LcIlCcjAAAAANfuGX-MfgABJnHPi1aO1EKL27lP'
  },
  apiKey: '971905f0-b728-42dc-bcb0-403a41b02c54',
  sessionToken: ''
}
