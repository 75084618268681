export default {
  thTicket: 'Ticket',
  thId: 'Rut',
  thName: 'Nombre',
  thCreate: 'Creación',
  thresolution: 'Resolución',
  thReason1: 'Motivo 1',
  thReason2: 'Motivo 2',
  thReason3: 'Motivo 3',
  btnCreateRequest: 'Crear Solicitud',
  btnAddInsistence: 'Agregar Insistencia',
  lateManagement: 'Estimado(a) cliente, usted posee las siguientes solicitudes en curso si desea insistir sobre alguna debe seleccionarla y luego apretar el botón Agregar Insistencia. Si tiene un problema distinto, puede crear una nueva solicitud.',
  currentManagement: 'Estimado(a) cliente, usted posee la siguiente(s) solicitud(es) en curso  y dentro del plazo comprometido. Si tienes un problema distinto, puede crear una nueva solicitud.',
  errorInsistence: 'Debe seleccionar un Ticket para crear una insistencia.'
}
