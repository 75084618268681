export default {
  message: 'Ingresa a continuación la información solicitada.',
  ownName: '¿Radica a nombre propio?',
  optionSelect: 'Seleccione',
  optionYes: 'Sí',
  optionNo: 'No',
  documentType: 'Tipo de documento *',
  documentNumber: 'Número de documento *',
  fullName: 'Nombre completo*',
  petitionerDocumentType: 'Tipo de documento (peticionario)',
  petitionerDocumentNumber: 'Numero de documento (peticionario)',
  petitionerFullName: 'Nombre (peticionario)',
  phone: 'Teléfono *',
  email: 'Correo Electrónico *',
  requiredfields: 'Campos obligatorios (*)',
  validInputMessage: 'Debe llenar los campos obligatorios',
  invalid_email: 'Verifique el email ingresado es valido',

  next_step: 'Siguiente',
  cancel: 'Cancelar'
}
