<template>
  <section class="main-header">
    <Banner />
    <Navigator />
  </section>
</template>

<script>
import Banner from './Banner'
import Navigator from './Navigator'

export default {
  name: 'Header',
  components: {
    Banner,
    Navigator
  },
  data () {
    return {
    }
  }
}
</script>
