export default {
  title: 'Nuevo Ticket',
  lbl_select_process: 'Selecciona un proceso',
  btnBack: 'Volver',
  onTime: 'Insistencia para ticket dentro de plazo de gestión.',
  outOfTime: 'Insistencia para ticket fuera de plazo de gestión.',
  ticket_created_successfully: 'El ticket fue creado con éxito.',
  placeholder_image_campaign: 'Imagen campaña',
  no_campaign_found: 'No se encontraron procesos asociados.'
}
